import React, { useState } from "react"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import { useEffect } from "react"
import axios from 'axios'

export default function Kontakt({ data }) {
  const seo = {
    title: "Kontakt - Klinika Chirurgii Plastycznej \"Pastucha\" w Szczecinie",
    description: "Adres: ul. Dębowa 3, 72-003 Dobra Szczecińska k/Szczecina | tel. 91 311 22 28, tel. +48 502 135 712 | E-mail: kontakt@mpastucha.pl",
    url: "https://mpastucha.pl/kontakt"
  }

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [content, setContent] = useState('');
  const [phone, setPhone] = useState('');
  const [send, setSend] = useState(false);
  const [fillRequired, setFillRequired] = useState(false);
  const [error, setError] = useState(false);

  const url = 'https://qu7dk8l4y1.execute-api.eu-central-1.amazonaws.com/mpastucha_contact/mpastucha_contact';

  useEffect(() => {
    let interval = setInterval(() => {
      if (typeof window !== "undefined" && window.ContactPageInit !== undefined && window.$) {
        clearInterval(interval)
        window.ContactPageInit()
      }
    }, 50)
  })

  const onSubmit = async (e) => {
    e.preventDefault();

    if (name === '' || email === '' || content === '') {
      setFillRequired(true);
      return;
    }

    const params = new URLSearchParams()
    params.append('name', name)
    params.append('email', email)
    params.append('content', content)
    params.append('phone', phone)

    const config = {

    }

    try {
      await fetch(url, {
        body: params,
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      })
      setFillRequired(false);
      setError(false);
      setSend(true);
      setName('');
      setEmail('');
      setContent('');
      setPhone('');
    } catch(error) {
      console.log(error)
      setFillRequired(false);
      setSend(false);
      setError(true);
    }
  }

  return (
    <Layout headerClasses={"mainheader-subpage mainheader-white"}>
      <SEO
        title={seo.title}
        description={seo.description}
        url={seo.url}
      />
      <header id="top" className="fake-placeholder" />
      <a href="tel:913112228" className="phonelink"><img src="/images/phone.svg" alt="Ikona telefon" /></a>
      <div className="fullwidth big-top-map-wrp">
        <div id="g_map" className="big-top-map" />
      </div>
      <section className="sct container">
        <div className="row">
          <div className="col-10sm-center">
            <div className="txt-cnt">
              <h1>Centrum Chirurgii Plastycznej Maciej Pastucha</h1>
            </div>
            <div className="sct row">
              <div className="col-8sm txt-ico txt-cnt-xs">
                <span className="ico-mailbox" />
                <p>ul. Dębowa 3 <br /> 72-003 Dobra Szczecińska k/Szczecina</p>
              </div>
              <div className="btn-list mobile-only"><a
                href="http://maps.google.com/?saddr=Current%20Location&daddr=53.495139,14.388917"
                className="btn btn-loc">nawiguj do kliniki</a></div>
              <div className="col-4sm txt-ico txt-ico-env txt-cnt-xs">
                <span className="ico-envelope" />
                <p>tel. 91 311 22 28 <br />tel. +48 502 135 712 <br /> fax 91 311 22 28 <br /> <a
                  href="mailto:kontakt@mpastucha.pl">kontakt@mpastucha.pl</a></p>
              </div>
            </div>
            <div className="row">
              <div className="confirmation hide">
                <p>Dziękujemy. Twoja wiadomość została wysłana. Skontaktujemy się z Tobą.</p>
              </div>
              <div role="form" className="wpcf7" id="wpcf7-f4-o1" lang="en-US" dir="ltr">
                <div className="screen-reader-response" />
                <form action="#" method="post" onSubmit={onSubmit}>
                  <div className="col-8sm">
                    <div className="row">
                      <div className="col-6sm">
                        <label>Imię i nazwisko*
                          <span className="name">
                            <input
                              type="text"
                              name="name"
                              size={40}
                              className="input"
                              placeholder="Imię i nazwisko"
                              value={name}
                              onChange={e => setName(e.target.value)}
                            />
                          </span>
                        </label>
                      </div>
                      <div className="col-6sm">
                        <label>Adres email*
                          <span className="email">
                            <input
                              type="email"
                              name="email"
                              size={40}
                              className="input"
                              placeholder="Adres email"
                              value={email}
                              onChange={e => setEmail(e.target.value)}
                            />
                          </span>
                        </label>
                      </div>
                      <div className="col-12">
                        <label>Wiadomość*
                          <span className="info">
                            <textarea
                              name="content"
                              cols={40}
                              rows={10}
                              className="input input-textarea"
                              placeholder="Treść wiadomości"
                              value={content}
                              onChange={e => setContent(e.target.value)}
                            />
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-4sm">
                    <div className="row">
                      <div className="col-12">
                        <label>Telefon
                          <span className="phone">
                            <input
                              type="tel"
                              name="phone"
                              placeholder="Numer telefonu"
                              size={40}
                              className="input"
                              value={phone}
                              onChange={e => setPhone(e.target.value)}
                            />
                          </span>
                        </label>
                      </div>
                      <div className="col-6sm">
                        <button className="btn btn-form">Wyślij</button>
                        {send &&
                          <div style={{color: 'green'}}>Wysłano wiadomość</div>
                        }
                        {fillRequired &&
                          <div style={{color: 'red'}}>Wypełnij wymagane pola</div>
                        }
                        {error &&
                        <div style={{color: 'red'}}>Wystąpił błąd, spróbuj ponownie</div>
                        }
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="txt-small">
              <p>*Pola oznaczone gwiazdką są wymagane</p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
